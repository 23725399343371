import React, { useState, useEffect } from "react";
import Attributes from "./attributes/Attributes";
import Price from "./price/Price";
import Logistic from "./logistic/Logistic";
import Sheet from "./sheet/Sheet";
import Title from "../components/name/Name";
import Recipe from "./recipe/Recipe";
import Multimedia from "./multimediaPhotos/Multimedia";
import MultimediaMainPhoto from "./multimediaPhotos/MultimediaMainPhoto";
import MultimediaVideo from "./multimediaVideo/MultimediaVideo";
import Name from "./name/Name";
import Sku from "./sku/Sku";
import CustomProductName from "./custom_product/Name"
import CustomProductTitle from '../components/Title';
import CustomProductTable from './custom_product/SkusTable';
import CustomSheet from "./custom_sheet/Sheet";
import PriceListGeneral from "./pricelist/PriceListGeneral";
import RelatedProducts from "./related_products/RelatedProducts";
import RelatedServices from "./related_services/RelatedServices";
import RelatedEdgebands from "./technical_products/TechnicalProducts";
import ProductListTable from '../components/ProductListTable';
import TitleButton from '../components/TitleButtonEdit';
import { connect } from "react-redux";
import global from "../../data.json";
import { useParams } from "react-router-dom";
import store from '../../redux/store'
import ModalCheck from './modal_check/ModalCheck';
import es from '../../es.json'

const Show = ({ ...props }) => {

  const [data, setData] = useState([])
  const [renderSuccess, setRenderSuccess] = useState(false)
  const [saveNewData, setSaveNewData] = useState(true)
  const [completedPercentage, setCompletedPercentage] = useState(0)
  const [currentRole, setCurrentRole] = useState("")
  const [productKind, setProductKind] = useState('simple')
  const [tableRowsPrincipal, setTableRowsPrincipal] = useState([])
  const [tableRowsAlternative, setTableRowsAlternative] = useState([])
  const [originalProductSkuList, setOriginalProductSkuList] = useState([])
  const [productSkuList, setProductSkuList] = useState([])
  const [enableEditProductList, setEnableEditProductList] = useState(true)
  const [productListModalState, setProductListModalState] = useState(false)
  let { id } = useParams()

  // When changing the id, the default values ​​must be reset.
  useEffect(() => {
    setData([])
    setRenderSuccess(false)
    setSaveNewData(true)
    firstProductLoad(id)
    setCurrentRole(localStorage.getItem('current_role'))
    store.dispatch({ type: 'RESET_PRICE_CHECK_STATE' })
  }, [id]);

  useEffect(() => {
    if (renderSuccess) {
      setTimeout( () => {
        calculatePercentOfInput()
      }, 1000);
    }
  }, [])

  useEffect(() => {
    if (renderSuccess) {
      setTimeout( () => {
        calculatePercentOfInput()
      }, 1000);
    }
  }, [renderSuccess])

  useEffect(() => {
    if (data.length != 0){
      setRenderSuccess(true)
    }
  }, [data])

  const firstProductLoad = (sku) => {
    fetch(`${global.api.url}/products/${sku}`, {
      method: "GET",
      mode: "cors",
      headers: global.api.header
    })
      .then(response => {
        response.json().then(response => {
          if(response.data == undefined){return}
          setData(response.data);
          let { product_type } = response.data.attributes;
          let sku_list_array
          let remplace_product_type =
            product_type === "SERVICIOS" ? "SERVICIOS DE VENTA" : product_type;
          remplace_product_type =
            remplace_product_type === "SERVICIOS" ||
            remplace_product_type === "SERVICIOS DE VENTA" ||
            remplace_product_type === "EXISTENCIA"
              ? remplace_product_type
              : "OTROS";
          props.loadProductData(remplace_product_type);
          setProductKind(response.data.attributes.kind) // Set product kind to see if recipe temple need to be rendered
          response.data.attributes.principal_recipe == null ? setTableRowsPrincipal([]): setTableRowsPrincipal(response.data.attributes.principal_recipe)
          response.data.attributes.alternative_recipe == null ? setTableRowsAlternative([]): setTableRowsAlternative(response.data.attributes.alternative_recipe)
          sku_list_array = response.data.attributes.product_list == null ? [] : buildSkuRows(response.data.attributes.product_list, response.data.attributes.configurable_options)
          setProductSkuList(sku_list_array)
          setOriginalProductSkuList([...sku_list_array])
        });
      })
      .catch(e => {
        console.log("error: ", e);
      });
  }

  const setTableRows = (rows, recipe, action) => {
    if(action == 'add'){
      if(recipe == 'principal_recipe') { setTableRowsPrincipal([...tableRowsPrincipal, rows]) }
      else { setTableRowsAlternative([...tableRowsAlternative, rows]) }
    }
    else{
      if(recipe == 'principal_recipe') { setTableRowsPrincipal(rows) }
      else { setTableRowsAlternative(rows) }
    }
  }

  // Enable edit custom product list
  // 
  // Toggler to enable/disable the edit of the product lists
  const enableEditCustomProductList = () => {
    setEnableEditProductList(!enableEditProductList)
    if(!enableEditProductList){
      setProductSkuList([...originalProductSkuList])
    }
  }

  // Set Product List
  // 
  // Change the data of the product list with the new list
  const setProductList = (list, action) => {
    if(action == 'delete') {
      setProductSkuList([...list])
    }else{
      setProductSkuList([...productSkuList,list])
    }
  }

  // Check edit product list
  // 
  // Check if the original product list is different than the product lists
  // if different raise modal
  const checkEditProductList = () => {
    let _ = require('lodash')
    if(_.isEqual(originalProductSkuList, productSkuList)){
    }else{
      productListToggleModal()
    }
    // enableEditCustomProductList()
  }

  // Product List Toggle modal
  // 
  // Toggle on/off the product list modal
  const productListToggleModal = () => {
    setProductListModalState(!productListModalState)
  }

  // Set Product List Data
  // 
  // Wrapper of the product list data
  // Convert to api format for submit
  const setProductListData = () => {
    let product_lists = {
      product_list_old: originalProductSkuList,
      product_list_new: productSkuList
    }
    let array = []
    let nextState = {}
    let nextAttributes = {}
    let {sku} = data.attributes
    nextState['type'] = 'product_change'
    nextState['id'] = sku
    nextAttributes['sku'] = sku
    nextAttributes['field_name'] = 'product_list'
    nextAttributes['field_detail'] = ''
    nextAttributes['old_value'] = product_lists[`product_list_old`]
    nextAttributes['new_value'] = product_lists[`product_list_new`]
    nextAttributes['title'] = es['ProductList']['product_list']
    nextAttributes['updated_by'] = localStorage.getItem('email');
    nextState['attributes'] = nextAttributes
    array.push(nextState)

    return array
  }

  const calculatePercentOfInput = () => {
    if (data && renderSuccess) {
      let allElementNotChecked = [];
      var totalpercent = 100;
      var allrequired = document.querySelectorAll(
        ".was-validated input[required]:not(.trix-input), .is-valid, .is-invalid, .was-validated select[required]"
      );
      allrequired.forEach(element => {
        var placeholder = element.getAttribute("placeholder");
        if (element.type === "radio") {
          var radio_check = false;
          var radio = document.getElementsByName(element.name);
          for (var n = 0, l = radio.length; n < l; n++) {
            if (radio[n].checked) {
              radio_check = true;
            }
          }
          if (!radio_check) {
            allElementNotChecked.push(placeholder);
          }
        }
        if (element.value.trim().length === 0 || element.value.trim() === "0") {
          allElementNotChecked.push(placeholder);
        }
      });
      var photosrequired = 0;
      photosrequired = 1;
      var photossuccess = 0;
      data.attributes.photos.forEach(value => {
        if (value.usage === "FotoAmbientada") {
          photossuccess++;
        }
      });
      if (photossuccess === 0) {
        allElementNotChecked.push("Foto Ambientada");
      }
      if(props.productKind == 'kit' || props.productKind == 'pack'){
        if(tableRowsPrincipal.length == 0){ allElementNotChecked.push(['Receta Principal', tableRowsPrincipal]) }
      }
      var numberElementNotChecked = allElementNotChecked.length;
      var percentsuccess =
        totalpercent -
        Math.round(
          (numberElementNotChecked * totalpercent) /
            (allrequired.length + photosrequired)
        );
      var bar = document.getElementById("percent-bar");
      var text_incomplete = document.getElementById("number-incomplete-input");
      var number = document.getElementById("number-percent-bar");
      bar.style.width = `${percentsuccess}%`;
      bar.setAttribute("aria-valuenow", percentsuccess);
      number.innerText = `${percentsuccess}%`;
      setCompletedPercentage(percentsuccess)
      text_incomplete.classList.add(
        numberElementNotChecked === 0 ? "text-success" : "text-danger"
      );
      text_incomplete.classList.remove(
        numberElementNotChecked === 0 ? "text-danger" : "text-success"
      );
      text_incomplete.innerText =
        numberElementNotChecked === 0
          ? "Campos completados"
          : `${numberElementNotChecked} campos incompletos`;
      let textToToolTip = "";
      allElementNotChecked.forEach((value, key) =>
        key === 0
          ? (textToToolTip = value)
          : (textToToolTip = `${textToToolTip}, ${value}`)
      );
      text_incomplete.dataset.tip = allElementNotChecked !== 0 ? textToToolTip : "";
    }
  }

  const checkRole = (role) => {
    return role == currentRole
  }

  const buildSkuRows = (product_list, configurable_options) => {
    let sku_list_array = []
    product_list.forEach(sku => {
      sku_list_array.push({ sku: sku, description: configurable_options[sku]['description'] })
    })
    return sku_list_array
  }

  const renderContent = () => {
    let {
      description,
      store_video,
      web_video,
      logistic_height,
      logistic_lenght,
      logistic_width,
      logistic_factor,
      logistic_weight,
      logistic_volume,
      materiality,
      product_provider_code,
      provider,
      product_type,
      type,
      valid,
      kit,
      compound,
      rotation,
      family,
      subfamily,
      barcode,
      business,
      category,
      source,
      height,
      lenght,
      width,
      space_type,
      collection,
      size,
      color,
      brand,
      package_type,
      purchase_currency,
      purchase_price,
      unit,
      selling_price,
      standar_cost,
      cost,
      sku,
      documents,
      related_products,
      related_services,
      technical_products,
      technical_length,
      name,
      gloss,
      status,
      stocks,
      pending_documents,
      publish_segment,
      manager,
      sell,
      moq,
      lead,
      vein,
      web_name
    } = data.attributes;
    let showDetailClass =  (checkRole('basic') || checkRole('manager') || checkRole('supervisor') || checkRole('admin') ) ? 'Product-show scroll scroll-show' : 'Product-show scroll scroll-show d-none'
    let showPrices = (checkRole('basic') || checkRole('manager') || checkRole('supervisor') || checkRole('admin') ) ? '' : 'd-none'
    return (
      
      <div className={showDetailClass}>
      <Title
        sku={sku === undefined || sku === null ? "" : sku}
        calculatePercentOfInput={calculatePercentOfInput}
        barPercentage={completedPercentage}
        status={status === undefined || status === null ? "" : status}
        publish_segment={
          publish_segment === undefined || publish_segment === null
            ? []
            : publish_segment
        }
      />
      <Name
        getProductData={firstProductLoad}
        name={name === undefined || name === null ? "" : name}
        gloss={gloss === undefined || gloss === null ? "" : gloss}
        sku={sku}
        barPercentage={completedPercentage}
        calculatePercentOfInput={calculatePercentOfInput}
      />
      <Attributes
        manager={manager}
        getProductData={firstProductLoad}
        product_type={product_type ? product_type : ""}
        type={type ? type : ""}
        valid={valid ? valid : ""}
        kit={kit ? kit : ""}
        compound={compound ? compound : ""}
        rotation={rotation ? rotation : ""}
        family={family ? family : ""}
        product_provider_code={product_provider_code ? product_provider_code : ""}
        provider={provider ? provider :  ""}
        subfamily={subfamily ? subfamily : ""}
        barcode={ barcode ? barcode : "" }
        business={business ? business : ""}
        category={category ? category : ""}
        source={source ? source : ""}
        unit={unit ? unit : ""}
        stocks={stocks ? parseFloat(stocks) : 0}
        pending_documents={pending_documents ? parseFloat(pending_documents) : 0}
        calculatePercentOfInput={calculatePercentOfInput}
        sell={sell ? sell : ""}
        sku={sku}
        moq={moq}
        lead={lead}
      />
      <Recipe
        setTableRows = { setTableRows }
        getProductData = { firstProductLoad }
        tableRowsPrincipal = { tableRowsPrincipal }
        tableRowsAlternative = { tableRowsAlternative }
        formKind = { 'show' }
        productKind = { productKind }
        sku = { sku }
      />
      <Sheet
        sku = { sku === undefined || sku === null ? "" : sku }
        getProductData = { firstProductLoad }
        brand = { brand === undefined || brand === null ? "" : brand }
        height = { height === undefined || height === null ? "" : height }
        lenght = { lenght === undefined || lenght === null ? "" : lenght }
        width = { width === undefined || width === null ? "" : width }
        space_type = { space_type === undefined || space_type === null ? "" : space_type }
        collection = { collection === undefined || collection === null ? "" : collection }
        size = { size === undefined || size === null ? "" : size }
        color = { color === undefined || color === null ? "" : color }
        materiality = { materiality === undefined || materiality === null ? "" : materiality }
        package_type = { package_type === undefined || package_type === null ? "" : package_type }
        documents = { documents === undefined || documents === null ? [] : documents }
        description = { description  ? description : "" }
        family = { family === undefined || family === null ? "" : family  }
        technical_products = { technical_products === undefined || technical_products === null ? "" : technical_products }
        technical_length = { technical_length === undefined || technical_length === null ? "": technical_length }
        calculatePercentOfInput = { calculatePercentOfInput }
        vein = { vein === undefined || vein === null ? "" : vein }
        web_name = { web_name === undefined || web_name === null ? "" : web_name }
      />
      <div className={showPrices}>
      <Price
        product_type={
          product_type === undefined || product_type === null
            ? ""
            : product_type
        }
        getProductData={firstProductLoad}
        purchase_currency={
          purchase_currency === undefined || purchase_currency === null
            ? ""
            : purchase_currency
        }
        purchase_price={
          purchase_price === undefined || purchase_price === null
            ? ""
            : purchase_price
        }
        selling_price={Math.round(
          selling_price === undefined || selling_price === null
            ? ""
            : selling_price
        )}
        standar_cost={Math.round(
          standar_cost === undefined || standar_cost === null
            ? ""
            : standar_cost
        )}
        cost={Math.round(cost === undefined || cost === null ? "" : cost)}
        calculatePercentOfInput={calculatePercentOfInput}
      />

      <PriceListGeneral
        getProductData={firstProductLoad}
        sku={sku === undefined || sku === null ? "" : sku}
        prices={data.attributes.prices}
        standarCost={Math.round(
          standar_cost === undefined || standar_cost === null
            ? ""
            : standar_cost
        )}
        cost={Math.round(cost === undefined || cost === null ? "" : cost)}
        calculatePercentOfInput={calculatePercentOfInput}
        saveNewData={saveNewData}
        setSaveNewData={setSaveNewData}
      />
      {product_type !== "SERVICIOS DE VENTA" && (
        <Logistic
          getProductData={firstProductLoad}
          calculatePercentOfInput={calculatePercentOfInput}
          sku={sku === undefined || sku === null ? "" : sku}
          logistic_height={
            logistic_height === undefined || logistic_height === null
              ? ""
              : logistic_height
          }
          logistic_lenght={
            logistic_lenght === undefined || logistic_lenght === null
              ? ""
              : logistic_lenght
          }
          logistic_width={
            logistic_width === undefined || logistic_width === null
              ? ""
              : logistic_width
          }
          logistic_volume={
            logistic_volume === undefined || logistic_volume === null
              ? ""
              : logistic_volume
          }
          logistic_factor={
            logistic_factor === undefined || logistic_factor === null
              ? ""
              : logistic_factor
          }
          logistic_weight={
            logistic_weight === undefined || logistic_weight === null
              ? ""
              : logistic_weight
          }
        />
        
      )}</div>
      <Multimedia
        product_type={
          product_type === undefined || product_type === null
            ? ""
            : product_type
        }
        getProductData={firstProductLoad}
        sku={sku === undefined || sku === null ? "" : sku}
        photos={data.attributes.photos}
      />
      <MultimediaVideo
        getProductData={firstProductLoad}
        web_video={
          web_video === undefined || web_video === null ? "" : web_video
        }
        store_video={
          store_video === undefined || store_video === null ? "" : store_video
        }
      />
      <RelatedProducts
        getProductData={firstProductLoad}
        related_products={
          related_products === undefined || related_products === null
            ? []
            : related_products
        }
      />
      <RelatedServices
        getProductData={firstProductLoad}
        related_services={
          related_services === undefined || related_services === null
            ? []
            : related_services
        }
      />
      
      <Sku
        getProductData={firstProductLoad}
        sku={sku === undefined || sku === null ? "" : sku}
        calculatePercentOfInput={calculatePercentOfInput}
      />
    </div>
    )
  }
  
  const renderCustomProductContent = () => {
    const {
      sku,
      status,
      publish_segment,
      name,
      gloss,
      configurable_options,
      configurable_attributes
    } = data.attributes;
    let {product_list} = data.attributes;
    const sku_list_array = buildSkuRows(product_list, configurable_options)
    const showDetailClass =  (checkRole('basic') || checkRole('manager') || checkRole('supervisor') || checkRole('admin') ) ? 'Product-show scroll scroll-show' : 'Product-show scroll scroll-show d-none'
    let roleClass = (checkRole('manager') || checkRole('supervisor') || checkRole('admin')) ? '' : 'd-none'
    return(
      <div className={showDetailClass}>
        <Title
          sku={sku === undefined || sku === null ? "" : sku}
          calculatePercentOfInput={calculatePercentOfInput}
          barPercentage={completedPercentage}
          status={status === undefined || status === null ? "" : status}
          publish_segment={
            publish_segment === undefined || publish_segment === null
              ? []
              : publish_segment
          }
          type={'custom_product'}
        />
        <div className="container">
          <CustomProductName
            getProductData={firstProductLoad}
            name={name === undefined || name === null ? "" : name}
            gloss={gloss === undefined || gloss === null ? "" : gloss}
            sku={sku}
            calculatePercentOfInput={(calculatePercentOfInput)}
            type='CustomProduct'
          />
        
          <>
            <ModalCheck modalcheck={productListModalState} idTable='modaltablecheckrecipe' data={ setProductListData() } 
              changeStateModalChech={ productListToggleModal } sku={ props.sku } id_btn_edit='btn_edit_custom_product_list' getProductData={firstProductLoad}/>
            <TitleButton disabled={enableEditProductList} id_div_edit='div_edit_custom_product_list' id_btn_edit='btn_edit_custom_product_list' 
                title={es['ProductList']['product_list'].toUpperCase()} id_div_save='div_send_custom_product_list' inputEnabler={ enableEditCustomProductList } roleVerification={ roleClass }
                check_data={checkEditProductList} title_edit='Listado'/>

            <ProductListTable
              name='none' tableRows= { productSkuList }
              setTableRows= { setProductList } 
              formKind= { enableEditProductList ? 'show' : 'edit' }
              calculatePercentOfInput={ (calculatePercentOfInput) }
            /> 
          </>
          <>
            <CustomSheet
              name = 'skus_list'
              attributes = { configurable_attributes }
              currentRole = {currentRole}
              productLoad = {firstProductLoad}
              sku = {sku}
              calculatePercentOfInput={ (calculatePercentOfInput) }
            />
          </>
            
          <CustomProductTitle title={es['ConfigurableOptions']['configurable_options'].toUpperCase()}/>

          <CustomProductTable
            name = 'attributes_list'
            sku_list = { sku_list_array }
            attributes = { configurable_attributes }
            configurable_options = { configurable_options }
            calculatePercentOfInput={ (calculatePercentOfInput) }
          />
          <MultimediaMainPhoto
            product_type = { "" }
            getProductData= { firstProductLoad }
            sku = { sku ? sku : "" }
            photos = { data.attributes.photos }
            requiredPhotos = { true }
          />

          <Sku
            getProductData={firstProductLoad}
            sku={sku === undefined || sku === null ? "" : sku}
            calculatePercentOfInput={calculatePercentOfInput}
          />
        </div>
      </div>
    )
  }

  const variableContent = () => {
    if(data.type == 'products'){
      return renderContent()
    }
    else{
      return renderCustomProductContent()
    }
  }

  return (
    <>
      {renderSuccess ?
        variableContent()
        :
        <div className='Product-show'>
          <h2>Cargando....</h2>
        </div>
      }
    </>
  )
}

const mapStateToProps = state => ({});

const mapDispatchToProps = dispach => ({
  changeRequiredState(typeSelected) {
    dispach({
      type: "CHANGE_REQUIRED",
      typeSelected
    });
  },
  loadProductData(typeSelected){
    dispach({
      type: "PRODUCT_LOADED",
      typeSelected
    })
  }
});

const wrapper = connect(mapStateToProps, mapDispatchToProps);
const component = wrapper(Show);

export default component;
