import React from 'react';
// import JsBarcode from 'jsbarcode';
import SelectAtribute from '../../components/SelectAttribute';
import InputTextAttribute from '../../components/InputTextAttribute';
import RadioButton from '../../components/RadioButton';
import Title from '../../components/Title';
import SwitchRadioButton from '../../components/SwitchRadioButton'
import  unit  from '../../../api/unit.json'
import sell  from '../../../api/sell.json'
import { connect } from 'react-redux'
import { getFamiliesData, getSubFamiliesData, getCountries, getCategories, getBrandsData, getManagersData, getColors, getMaterials, getSupplierCodes, getSpaceCodes } from '../../../api/APIGet'

// import './Attributes.css';

class Attributes extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      enabled: false,
      families: [],
      subfamilies: [],
      countries: [],
      categories: [],
      brands: [],
      managers: [],
      suppliercodes: []
    };
  }
  
  componentDidMount(){
    getFamiliesData().then( response => this.setState({ families: response.data.attributes }) )
    getSubFamiliesData().then( response => this.setState({ subfamilies: response.data.attributes }) )
    getCountries().then( response => this.setState({ countries: response }) )
    getCategories().then( response => this.setState({ categories: response.data.attributes }) )
    getBrandsData().then( response =>  this.setState({ brands: response.data.attributes}) ) 
    getManagersData().then( response => { this.setState({ managers: response.data.attributes}) })
    getSupplierCodes().then( response => { this.setState({ suppliercodes: response}) })
  }

  
  render() {
    let {productTypeRequired, typeRequired, validRequired, rotationRequired, familyRequired, 
      productProviderCodeRequired, providerRequired, subfamilyRequired, barcodeRequired, 
      businessRequired, categoryRequired, sourceRequired, unitRequired} = this.props.requiredAttribute

    return(
      <div className='row'>
        <div className='col-md-12'>
          <Title title='ATRIBUTOS'/>
        </div>
        <div className='col-md-12 mb-2'>
          <RadioButton
          options={['EXISTENCIA','SERVICIOS DE VENTA']}  
          calculatePercentOfInput = {this.props.calculatePercentOfInput}
          title=''
          name='product_type'
          required={productTypeRequired}
          description='Indica que tipo de producto es.'
          flexline_name='tipoproducto'
          changeElementRequiered={true}
          onChangeProductType={this.props.onChangeProductType}
          />
        </div>

        <div className='col-md-6'>
          {/* <InputTextAttribute title='Código proveedor' name='provider' id='id_input_provider'   
          calculatePercentOfInput = {this.props.calculatePercentOfInput}
          type='text' description='Código manejado por el proveedor del producto'
          required={providerRequired}
          flexline_name='analisisproducto17'/> */}

          <SelectAtribute id='id_input_provider'
            options={this.state.suppliercodes}
            title='Código proveedor' name='provider'
            required={providerRequired}
            description='Código manejado por el proveedor del producto'
            calculatePercentOfInput={this.props.calculatePercentOfInput}
            flexline_name='analisisproducto17' />
        </div>  
        
        <div className='col-md-6'>
          <InputTextAttribute title='Código de compra' name='product_provider_code' id='id_product_provider_code'  
          type='text'
          calculatePercentOfInput = {this.props.calculatePercentOfInput}
          required={productProviderCodeRequired}
          description='Indica el código de proveedor del producto.'
          flexline_name='analisisproducto1'/>
        </div>

        <div className='col-md-6'>
          <InputTextAttribute title='Código de barra' name='barcode' id='bar_code'
           notRequired={true}
           type='text' 
           calculatePercentOfInput = {this.props.calculatePercentOfInput}
           required={barcodeRequired}
           description='Código de barra para control interno de la bodega.'
           flexline_name='analisisproducto7'/>
        </div>
        
        <div className='col-md-6'>
          <SelectAtribute 
          id='select_business' options={['COMPONENTES','ORGANIZACION','ELECTRO Y MENAJE','FABRICADOS','INSTALADOS','SERVICIOS']}  
          calculatePercentOfInput = {this.props.calculatePercentOfInput}
          title='Negocio' name='business' 
          required={businessRequired}
          description='Define negocio al cual pertenece el código.'
          flexline_name='analisisproducto19'
          duplicate = 'clasificador1'/>
        </div>

        <div className='col-md-6'>
          <SelectAtribute id='attributes_select_family' 
          options={this.state.families}  
          calculatePercentOfInput = {this.props.calculatePercentOfInput}
          title='Familia'
          name='family' 
          required={familyRequired}
          description='Indica la FAMILIA de producto a la cual pertenece.'
          flexline_name='familia'
          duplicate = 'clasificador3'/>
        </div> 

        <div className='col-md-6'>
          <SelectAtribute id='attributes_select_subfamily' 
          options={this.state.subfamilies}  
          calculatePercentOfInput = {this.props.calculatePercentOfInput}
          title='Sub-Familia' name='subfamily'  
          required={subfamilyRequired}
          description='Indica a que Subfamilia pertenece, cada subfamilia es exclusiva de una familia.'
          flexline_name='subfamilia'
          duplicate = 'clasificador4'/>
        </div>

        <div className='col-md-6'>
          <SelectAtribute id='attributes_select_source' 
            options={this.state.countries}  
            title='Procedencia' 
            name='source'
            required={sourceRequired}
            description='Indica el origen del producto.'
            calculatePercentOfInput = {this.props.calculatePercentOfInput}
            flexline_name='procedencia'/>
        </div> 
        
        <div className='col-md-6'>
          <SelectAtribute id='select_type' 
          options={['NUEVO','LINEA','DE BAJA','A PEDIDO', 'MUESTRA', 'TIPO B']}  
          calculatePercentOfInput = {this.props.calculatePercentOfInput}
          title='Tipo'
          name='type' 
          required={typeRequired}
          description='Indica en que condición o status se encuentra el producto.'
          flexline_name='tipo'/>
        </div> 

        <div className='col-md-6'>
          <SelectAtribute 
          id='select_category' 
          options={this.state.categories} 
          title='Categoría' 
          name='category' 
          disabled={this.state.enabled} 
          required={categoryRequired}
          description='Uso para ordenamiento web.'
          calculatePercentOfInput = {this.props.calculatePercentOfInput}
          flexline_name = 'clasificador2'/>
        </div>

        <div className='col-md-6'>
          <SelectAtribute 
          id='select_unit' 
          options={unit.unit}
          title='Unidad de medida' 
          name='unit' 
          disabled={this.state.enabled} 
          required={unitRequired}
          description='Unidad de medida del producto'
          calculatePercentOfInput = {this.props.calculatePercentOfInput}
          flexline_name = 'unidad'/>
        </div>

        <div className='col-md-6'>
            <SelectAtribute 
            id='select_manager' 
            options={this.state.managers} 
            value={this.props.manager}
            title='Encargado' 
            name='manager' 
            disabled={this.state.enabled} 
            required={true}
            description='Encargado'
            flexline_name = 'analisisproducto20'/>
        </div>

        <div className='col-md-6'>
            <SelectAtribute 
            id='select_sell' 
            options={sell.sell} 
            value={this.props.sell}
            title='Compra y Venta' 
            name='sell' 
            disabled={this.state.enabled} 
            required={true}
            description='Compra Venta'
            flexline_name = 'analisisproducto11'/>
        </div>

        <div className='col-md-6'>
          <InputTextAttribute title='MOQ' name='moq' id='moq'
            notRequired={true}
            type='number' 
            calculatePercentOfInput = {this.props.calculatePercentOfInput}
            description='Cantidad mínima de compra.'
            flexline_name='lotecompra'
            disabled={this.state.enabled}
          />
        </div>

        <div className='col-md-6'>
          <InputTextAttribute title='LEAD' name='lead' id='lead'
            notRequired={true}
            type='number' 
            calculatePercentOfInput = {this.props.calculatePercentOfInput}
            description='Cantidad en días que se demora en reabastecerse el producto.'
            flexline_name='diascompra'
            disabled={this.state.enabled}
          />
        </div>

        <div className='col-md-12'>
          <RadioButton
          options={['A','B','C','D','N']}  
          calculatePercentOfInput = {this.props.calculatePercentOfInput}
          title='Rotación'
          name='rotation' 
          required={rotationRequired}
          description='Indica prioridad para la compra, factor de seguridad para la compra.'
          flexline_name='subtipo'/>
        </div>

        <div className='col-md-6'>
          <SwitchRadioButton
            id='state_product' 
            value= {true} 
            title='Vigente'
            name='valid'
            required={validRequired}
            description='Indica si está disponible o no para la venta y control de stock.'
            flexline_name = 'vigente' 
            />
        </div>
      </div>

    );
  }
}

const mapStateToProps = (state) => ({
  requiredAttribute: state.elementRequiredReducer.attribute
})

const mapDispatchToProps = (dispach) => ({})

const wrapper = connect(mapStateToProps, mapDispatchToProps)
const component = wrapper(Attributes)

export default component;
