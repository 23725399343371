import React from 'react';
import InputTextAttributeFull from '../../components/InputTextAttributeFull';
import Title from '../../components/Title';
import InputTextAttribute from '../../components/InputTextAttribute';


class Attributes extends React.Component {

    constructor(props) {
        super(props);
        this.state = { enabled: false };
    }

    render() {
        return (
                <div>
                <InputTextAttribute title='Nombre Web' name='web_name' id='id_input_web_name' disabled={this.props.enabled}
                        value={this.props.web_name} type='text'
                        description=''
                        flexline_name=''
                        required={true}
                        calculatePercentOfInput={this.props.calculatePercentOfInput}
                         />
                </div>
        );
    }
}
export default Attributes;